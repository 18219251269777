<template>
  <div class="news-item">
    <header class="page-header">
      <PageTitle :item="newsItem"/>
    </header>

    <div class="main-content">
      <div class="pane table-pane">
        <formulate-form v-if="newsItem && !isLoading" v-model="newsItem" @submit="submit">
          <formulate-input v-if="!fileData" :file-upload-complete="uploadIconComplete()" :file-upload-progress="uploadIconComplete" :upload-url="uploadUrl" :uploader="uploader" error-behavior="submit" label="Afbeelding" name="files" type="file" upload-behavior="live"></formulate-input>
          <div v-if="fileData" class="image-placeholder">
            <img :src="fileData" alt="Test" width="50"/>
            <button v-if="fileData" class="btn btn-remove-file" type="button" @click="removeFileData()">Bestand verwijderen</button>
          </div>
          <formulate-input label="Highlight" name="highlight" type="checkbox"></formulate-input>
          <formulate-input label="Sub highlight" name="sub_highlight" type="checkbox"></formulate-input>

          <formulate-input #default="{ index }" groupRepeatable-class="translations-group formulate-input-group-repeatable" name="translations" type="group">
            <h3>Vertaling: {{ activeLocalesBack[getLangCode(newsItem, index)] }}</h3>
            <TranslationsRemoveButton :index="index" :item="newsItem"/>

            <formulate-input name="lang_code" type="hidden"></formulate-input>
            <formulate-input label="Titel" name="title" type="text" validation="required"></formulate-input>
            <formulate-input label="Teaser" name="teaser" type="textarea" validation="required"></formulate-input>
            <formulate-input type="tiptap" v-model="getTranslation(newsItem, getLangCode(newsItem, index)).body" label="Body"></formulate-input>
          </formulate-input>

          <TranslationsAddButtons :item="newsItem"/>

          <formulate-input :label="isLoading ? $t('Even geduld...') : $t('Opslaan')" input-class="btn" type="submit"/>
        </formulate-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import TranslationsAddButtons from '@/components/admin/TranslationsAddButtons'
import TranslationsRemoveButton from '@/components/admin/TranslationsRemoveButton'
import PageTitle from '@/components/admin/PageTitle'

export default {
  name: 'NewsItemView',
  components: { PageTitle, TranslationsRemoveButton, TranslationsAddButtons },
  data () {
    return {
      isLoading: true,
      newsItemId: this.$route.params.id,
      newsItem: null,
      fileData: null,
      uploader: ApiService.getAxiosInstance(),
      uploadUrl: '/files/news'
    }
  },
  async mounted () {
    if (this.newsItemId) {
      await this.fetchNewsItem(this.newsItemId)
    }

    if (this.newsItem?.image_file_id) {
      this.fileData = (await ApiService.getFile(this.newsItem.image_file_id)).data
    }

    if (!this.newsItem) {
      this.newsItem = {
        translations: [{ lang_code: this.$i18n.locale }],
        files: []
      }
    }

    this.isLoading = false
  },
  methods: {
    async removeFileData () {
      await ApiService.deleteFile(this.newsItem.image_file_id)
      this.fileData = null
      this.newsItem.image_file_id = null
    },
    uploadIconComplete: async function () {
      if (this.newsItem?.files?.results?.[0] !== undefined) {
        this.newsItem.image_file_id = this.newsItem.files.results[0][0]
        this.uploading = false
      }
    },
    fetchNewsItem: async function (id) {
      this.newsItem = (await ApiService.fetchNewsItem(id)).data
    },
    async submit (newsItem) {
      this.isLoading = true
      const res = await ApiService.saveNewsItem(newsItem)

      if (res.status === 200) {
        notification(this.$t('Nieuwsbericht succesvol aangemaakt!'))
        await this.$router.push({ name: 'admin.news' })
      }
      this.isLoading = false
    }
  }
}
</script>
